// 1.0.0 Text white fix
// 1.0.1 Not white *
// 2.0.0 Also work with normal click
// 3.0.0 Add tooltip when disabled
// 3.0.1 Add display inline to holder
// 3.0.2 Tooltip on button element
// 3.1.2 Added spin to button-interactive
// 4.0.0 Switched to Font-Awesome kits
import { Component, EventEmitter, Input, Output } from '@angular/core'
import { timer } from 'rxjs'
import { NgIf } from '@angular/common'
import { TooltipModule } from 'ngx-bootstrap/tooltip'

@Component({
    selector: 'button-interactive',
    styles: [
        `
            /* .button-holder {
				display: inline;
			} */

            button {
                position: relative;
                white-space: nowrap;
            }
            button[disabled] {
                cursor: not-allowed;
            }
            button .center-xy {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }
        `,
    ],
    template: `
        <span [class.d-grid]="block">
            <button
                class="btn btn-{{ size }} btn-{{ color }} {{ class }}"
                [class.btn-block]="block"
                [class.text-white]="_classTextNotWhite.includes(color) ? false : true"
                [disabled]="disabled || loading || error ? true : null"
                [triggers]="'hover click'"
                [tooltip]="disabled ? disabledMessage : loading ? loadingMessage : null"
                [placement]="'top'"
                (click)="buttonClick($event)"
            >
                <span [style.visibility]="loading || error || complete ? 'hidden' : 'visible'">
                    <i *ngIf="icon" class="{{ icon }} fa-fw" [class.fa-spin]="iconSpin"></i>
                    <ng-content></ng-content>
                </span>
                <span class="center-xy">
                    <i *ngIf="loading" class="fa-light fa-spinner-third fa-fw fa-spin"></i>
                    <i *ngIf="error" class="fa-solid fa-exclamation fa-fw"></i>
                    <i *ngIf="complete" class="fa-light fa-check fa-fw"></i>
                </span>
            </button>
        </span>
    `,
    standalone: true,
    imports: [TooltipModule, NgIf],
})
export class ButtonInteractiveComponent {
    @Input() color = 'primary'
    @Input() class = 'float-end'
    @Input() size = 'sm' // [sm md lg]
    @Input() block = false

    @Input() icon
    @Input() iconSpin: boolean = false

    // States
    @Input() disabled = false
    @Input() disabledMessage

    @Input() loading = false
    @Input() loadingMessage = 'Loading...'

    @Input() error = false
    @Input() complete = false

    // Contains a function which always returns a promise
    @Input() clickPromise: (any) => Promise<any> = null
    @Output() clickCallback = new EventEmitter()
    @Output() click = new EventEmitter()

    _classTextNotWhite = [
        'link',

        'outline-primary',
        'outline-secondary',
        'outline-success',
        'outline-info',
        'outline-warning',
        'outline-danger',
        'outline-light',
        'outline-dark',
    ]

    buttonClick($event) {
        // Check if we have a normal click
        const clickNormal = this.click.observers.length > 0
        if (clickNormal) {
            $event.stopPropagation()
            return this.click.emit($event)
        }

        // Check if we have a callback click
        const cb = () => {
            this.loading = false
        }
        const clickCallback = this.clickCallback.observers.length > 0
        if (clickCallback) {
            this.loading = true
            return this.clickCallback.emit(cb.bind(this))
        }

        // Check if we have a callback
        const clickPromise = this.clickPromise
        if (clickPromise) {
            this.loading = true

            const p = clickPromise($event)
            const pIsPromise = p && p.finally
            if (!pIsPromise) return (this.loading = false)
            return p
                .then(() => {
                    timer(25).subscribe((_) => {
                        this.loading = false
                        this.complete = true
                    })
                    timer(200).subscribe((_) => (this.complete = false))
                })
                .catch((e) => {
                    console.error('Caught error')
                    console.error(e)
                    this.loading = false
                    this.error = true
                    timer(2000).subscribe((_) => (this.error = false))
                })
        }
    }
}
